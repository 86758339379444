<template>
	<div>
		<div  style="display: flex;justify-content: space-around;">
			<div class="overviewItemBox">
				<div class="overviewItem bg2">
					<div class="info">
						<p class="overviewItemTitle">设备总数</p>
						<p class="overviewItemText">{{form.deviceNum}}</p>
					</div>
					<div>
						<img src="../../assets/total.png" alt="" style="margin-top: 35px;">
					</div>
				</div>
			</div>
			<div class="overviewItemBox">
				<div class="overviewItem bg3">
					<div class="info">
						<p class="overviewItemTitle">设备报警数</p>
						<p class="overviewItemText">{{form.alarmNum}}</p>
					</div>
					<div>
						<img src="../../assets/alarm.png" alt="" style="margin-top: 35px;">
					</div>
				</div>
			</div>
			<div class="overviewItemBox">
				<div class="overviewItem bg4">
					<div class="info">
						<p class="overviewItemTitle">设备在线数</p>
						<p class="overviewItemText">{{form.onlineNum}}</p>
					</div>
					<div>
						<img src="../../assets/inline.png" alt="" style="margin-top: 35px;">
					</div>
				</div>
			</div>
			<div class="overviewItemBox">
				<div class="overviewItem bg6">
					<div class="info">
						<p class="overviewItemTitle">设备离线数</p>
						<p class="overviewItemText">{{form.offlineNum}}</p>
					</div>
					<div>
						<img src="../../assets/outline.png" alt="" style="margin-top: 35px;">
					</div>
				</div>
			</div>
		</div>
		<div class="title">设备类型数量统计</div>
		<div style="height: 600px;" id="deviceStatusList"></div>
	</div>
</template>
<script>
	import * as echarts from 'echarts';
	export default{
		data(){
			return {
				form:{
					deviceNum : 10,
					alarmNum : 10,
					onlineNum : 10,
					offlineNum : 10,
				},
				xList:[],
				yList:[]
			}
		},
		mounted(){
			
			this.$api.homePage_CardList().then(res => {
				if (res.code == 200) {
					this.form = res.data
					this.$api.homePage_DeviceByModel().then(res => {
						if (res.code == 200) {
							for ( var i = 0 ; i < res.data.length ; i++ ) {
								this.xList.push( res.data[i].deviceModel );
								this.yList.push( res.data[i].num );
							}
							this.getDeviceStatusList();
						}else{
							this.$message({
								type: 'error',
								message: "获取数据失败"
							});
						}
					});
				}else{
					this.$message({
						type: 'error',
						message: "获取数据失败"
					});
				}
			});
		},
		methods: {
			getDeviceStatusList(){
				var myChart = echarts.init(document.getElementById('deviceStatusList'));
				var data1 = this.xList;
				var data2 = this.yList;
				var dailySellMoneyOption = {
					tooltip : {
				        trigger: 'axis',
				        axisPointer: {
				            type: 'cross',
				            label: {
				                backgroundColor: '#413EFA'
				            },
				        },
				        formatter:function( data ){
				        	var str = data[0].axisValue + '<br>';
				        	var money = '<span class="xiaoyuan" style="background:#413EFA"></span>' + data[0].value + ' 台';
				        	return str + money;
				        }
				    },
				    xAxis: {
				        type: 'category',
				        data: this.xList,
				        //设置轴线的属性
				        axisLine:{
				            lineStyle:{
				                color:'#0A6BFF',
				                width:0,//这里是为了突出显示加上的
				            }
				        },
				        //设置轴线的属性
				       	axisLabel: {
				           	interval : 0,
				           	// rotate : 40,
				           	fontSize : 16   
				        }
				    },
				    yAxis: {
				        type: 'value',
				        //设置轴线的属性
				        axisLine:{
				            lineStyle:{
				                color:'#0A6BFF',
				                width:0,//这里是为了突出显示加上的
				            }
				        },
				        
				        //设置轴线的属性
				       	axisLabel: {
				           	interval : 0,
				           	// rotate : 40,
				           	fontSize : 16   
				        }
				    },
				    grid: {
				    	top : '8%',
				        left: '3%',
				        right: '3%',
				        bottom: '8%',
				        containLabel: true
				    },
				    series: [ 
				        {
				            type: 'bar',
					        barWidth: 10,
					        barGap: 200,
					        barCategoryGap: 200,
					        label: {
					            show: true, // 柱子顶部的数值
					            position: 'top',
					            top: 0,
					            fontSize: 16,
					            offset: [0, -10],
					            formatter: function(params) {
					                return params.value;
					            }
					        },
				            itemStyle: {
					            normal: {
					                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
					                    offset: 0,
					                    color: "#4729FB" // 0% 处的颜色
					                }, {
					                    offset: 0.5,
					                    color: "#3077F7" // 50% 处的颜色
					                }, {
					                    offset: 1,
					                    color: "#1FB0F4" // 100% 处的颜色
					                }], false)
					            }
					        },
				            data: this.yList
				        }
				    ],
				};
				myChart.setOption( dailySellMoneyOption );
				
			}
		},
	}
</script>
<style type="text/css">
	.overviewItemBox{
		width: 15%;
		height: 120px;
		text-align: left;
	}
	.overviewItem{
		height: 100%;
		width: 100%;
		border-radius: 70px 4px 4px 4px;
		box-shadow: 2px 3px 5px 1px #136aff36;
		cursor: pointer;
		position: relative;
		padding-right: 80px;
		display: flex;
	}
	.overviewItem .info{
		height: 100%;
		width: 100%;
		border-radius: 70px 4px 4px 4px;
		color: #FFFFFF;
		text-align: center;
	}
	.overviewItem .overviewItemTitle{
		margin-top: 30px;
		margin-bottom: 10px;
		font-size: 18px;
	}
	.overviewItem .overviewItemText{
		font-size: 22px;
	}
	.bg2{
		background-color: #89cec4;
		background-image: -webkit-gradient(linear, 0 100%, 0 0, color-stop(0.35, #89cec4), to(#3fc6b7));
		background-image: -webkit-linear-gradient(90deg, #89cec4 35%, #3fc6b7 100%);
		background-image: -moz-linear-gradient(90deg, #89cec4 35%, #3fc6b7 100%);
		background-image: -o-linear-gradient(90deg, #89cec4 35%, #3fc6b7 100%);
		background-image: linear-gradient(90deg, #89cec4 35%, #3fc6b7 100%);
	}
	.bg3{
		background-color: #ff9f95;
		background-image: -webkit-gradient(linear, 0 100%, 0 0, color-stop(0.35, #ff9f95), to(#f6637d));
		background-image: -webkit-linear-gradient(90deg, #ff9f95 35%, #f6637d 100%);
		background-image: -moz-linear-gradient(90deg, #ff9f95 35%, #f6637d 100%);
		background-image: -o-linear-gradient(90deg, #ff9f95 35%, #f6637d 100%);
		background-image: linear-gradient(90deg, #ff9f95 35%, #f6637d 100%);
	}
	.bg4{
		background-color: #cd7bf9;
		background-image: -webkit-gradient(linear, 0 100%, 0 0, color-stop(0.35, #cd7bf9), to(#934beb));
		background-image: -webkit-linear-gradient(90deg, #cd7bf9 35%, #934beb 100%);
		background-image: -moz-linear-gradient(90deg, #cd7bf9 35%, #934beb 100%);
		background-image: -o-linear-gradient(90deg, #cd7bf9 35%, #934beb 100%);
		background-image: linear-gradient(90deg, #cd7bf9 35%, #934beb 100%);
	}
	.bg6{
		background-color: #34a9ff;
		background-image: -webkit-gradient(linear, 0 100%, 0 0, color-stop(0.35, #34a9ff), to(#0563ff));
		background-image: -webkit-linear-gradient(90deg, #34a9ff 35%, #0563ff 100%);
		background-image: -moz-linear-gradient(90deg, #34a9ff 35%, #0563ff 100%);
		background-image: -o-linear-gradient(90deg, #34a9ff 35%, #0563ff 100%);
		background-image: linear-gradient(90deg, #34a9ff 35%, #0563ff 100%);
	}
	.title{
		margin: 45px 0 0 25px;
		font-size: 20px;
		font-weight: bold;
		font-family: "microsoft yahei";
		border-left: 3px solid #0969FF;
		text-indent: 1em;
		position: relative;
	}
</style>